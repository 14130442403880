import { initializeApp, getApps } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAV5Y2WhlkvQNiKoHsQlPSE8PIqD9kwWOI",
  projectId: "medicalsupplierz-81f40",
  messagingSenderId: "594713697934",
  appId: "1:594713697934:web:2cf5622585a51afa3773df",
  vapidKey:
    "BCCXMGpnb6cLGdrfoZ9jwwLL0KEeau3jsMFBp5voymyCcKH4o1aN_jTRY0eFGj7WeTgQaTPwe1aHtkG8jIbQ5f8",
};
const apps = getApps();
const app = !apps.length ? initializeApp(firebaseConfig) : apps[0];

let messaging = null;
if ("serviceWorker" in navigator) {
  messaging = getMessaging(app);
}

export { messaging, firebaseConfig };
